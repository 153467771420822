<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>PART LOCATIONS ({{partLocationList.total}})</h5>
				</CCol>
	      <CCol lg="6">
			  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('part_locations').create"
						size="sm" style="margin-right: 10px" 
						color="success" @click="toggleModal()" 
						class="float-lg-right"><i class="fa fa-plus"></i>Add Part Location
					</CButton>
		      		
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @booking-type-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
			</CRow> 
			<hr>
		<CRow>
	        <!-- <CCol lg="12" class="text-center" v-if="isLoading"> 
				  <div class="spinner-border m-5" role="status">
						<span class="sr-only">Loading...</span>
					</div>
			  </CCol> -->
		      <CCol lg="12">  
				  <div class="table-responsive-sm table-responsive-md">
			    	<CDataTable
					  :loading="isLoading"
				      :items="partLocationList.data"
				      :fields="fields"
					  	   hover
         				  striped
						  oulined
						  
				    >  
				      	<template #action="{item}">
					        <td style="padding: 5px; width: 100px">
					        	<CDropdown 
					                toggler-text="Select"  
										size="sm"
						                color="info"
					            >
					                <CDropdownItem v-if="config.getPermission('part_locations').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
						            <CDropdownItem v-if="config.getPermission('part_locations').delete" @click="deleteRow(item)"><i class="fa fa-trash">Delete</i></CDropdownItem> 
					            </CDropdown>
					        </td>
				      	</template>
				    </CDataTable> 
				    <CPagination
			          	:active-page.sync="currentPage"
			          	:pages="Math.ceil(partLocationList.total / 20)"
			          	:activePage="currentPage"
			          	@update:activePage="updatePage"
			        />
				  </div>
	      	
	      </CCol>
	    </CRow>
	    <CModal :title="title" :show.sync="formModal" color="info">
           	<form @submit.prevent="submit">
           		<CRow>
					<CCol lg="12">
						<label>Part Location<span class="text-danger">*</span></label>
						<CInput
		                  placeholder="Enter part location"
		                  v-model="partLocationName"
		                  required="true"
		                />
					</CCol>
				</CRow>
	            <div slot="footer" class="w-100">
	            	<CButton size="sm" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	                <CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>
	                
	            </div>
				
           	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>
		</CCard>
	</div>
</template>
<script>
import CTableWrapper from '../../base/Table.vue'
import usersData from '../../users/UsersData'
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'

export default {
	mounted(){
		console.log(config);
		this.getPartLocationList();
	},
	data(){
		return{
			config,
			formModal: false,
			partLocationList: [],
			partLocationName: "",
			fields: [
				{
					key: 'setting_name', 
					label: 'Name'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1,
			editMode: false,
				showCollapse: false,
			selectedRow: {},
			filterName: "",
			isLoading: false
		}
	},
	name: 'Tables',
	components: { CTableWrapper, Search  },
	methods: {
		updatePage(data){
			this.currentPage = data;
			this.getPartLocationList();
		},
	    shuffleArray (array) {
	      for (let i = array.length - 1; i > 0; i--) {
	        let j = Math.floor(Math.random() * (i + 1))
	        let temp = array[i]
	        array[i] = array[j]
	        array[j] = temp
	      }
	      return array
	    },

	    getShuffledUsersData () {
	      return this.shuffleArray(usersData.slice(0))
	    },

	    toggleModal(){
	    	this.formModal = !this.formModal;
			this.title = "Add Part Location";
	    },

	    submit(){
	    	var ax = {};
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/setting/"+this.selectedRow.id, {
		    		setting_type: "part_location",
		    		setting_name: this.partLocationName
		    	})
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/setting", {
		    		setting_type: "part_location",
		    		setting_name: this.partLocationName
		    	})
	    	}
	    	ax.then(response => {
	    		var text = 'Part location successfully added!';
	    		if(this.editMode){
	    			text = 'Part location successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.partLocationName = ""
	    		this.getPartLocationList();
	    	})
	    },
		search(event){
	    	this.filterName = event.name;
	    	this.getPartLocationList();
	    },

	    getPartLocationList(){

			this.isLoading=true;
	    	axios.get(config.api_path+'/setting?setting_type=part_location&setting_name='+this.filterName+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.partLocationList = response.data;
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})

	    },

	    updateModal(item){
			this.title = "Update Part Location";
	    	this.selectedRow = item;
	    	this.partLocationName = item.setting_name;
	    	this.formModal = true;
	    	this.editMode = true;

	    },

	    deleteRow(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this part location?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/setting/'+item.id)
			    	.then(response => {
			    		this.getPartLocationList(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Part location successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    }

 	}
}
</script>
